<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="comboItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="col1"
            label="지게차/운반구"
            v-model="searchParam.col1"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="지게차/운반구 운행인원 목록"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="내부운전자추가" icon="add" @btnClicked="addUser" v-if="editable" />
          <c-btn label="외부운전자추가" icon="add" @btnClicked="addRow" v-if="editable" />
          <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeRowMst" v-if="editable" />
          <c-btn label="LBLSAVE" icon="save" @btnClicked="saveMst" v-if="editable" />
          <c-btn label="LBLSEARCH" icon="search"  @btnClicked="getList"/>
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }"> 
        <template v-if="col.name ==='col2'">
          <c-text-column
            v-if="props.row['insideOutsideTypeCd'] === 'N'"
            :editable="editable"
            :props="props"
            :col="col"
            v-model="props.row['col2']"
            @datachange="datachange(props)"
          />
          <span v-else>
            {{props.row['col2']}}
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'fork-lift-person',
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      comboItems:[
        {
          code:"1",
          codeName:"4륜 전동 지게차/운반구 ABC"
        },
        {
          code:"2",
          codeName:"4륜 전동 지게차/운반구 DEF"
        },
        {
          code:"3",
          codeName:"4륜 전동 지게차/운반구 GHI"
        },
      ],
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.grid.columns = [
        {
          required: true,
          name: 'plantCd',
          field: 'plantCd',
          label: 'LBLPLANT',
          align: 'left',
          style: 'width:120px',
          type: 'plant',
          sortable: false,
        },
        {
          name: 'col5',
          field: 'col5',
          label: '내/외부',
          align: 'center',
          style: 'width:80px',
          sortable: false,
        },
        {
          required: true,
          name: 'col2',
          field: 'col2',
          label: '지게자 운전자',
          align: 'center',
          style: 'width:120px',
          type: 'custom',
          sortable: false,
        },
        {
          name: 'attach',
          field: 'attach',
          label: '관련 자격증 사진',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'FORK_LIFT_PERSON',
          keyText: 'safetyGearId',
          sortable: false,
        },
        {
          required: true,
          name: 'col1',
          field: 'col1',
          valueName: 'codeName',
          label: '지게차/운반구',
          align: 'center',
          style: 'width:200px',
          sortable: false,
          type: 'select',
          comboItems: [
            { code: '1', codeName: '4륜 전동 지게차/운반구 ABC' },
            { code: '2', codeName: '4륜 전동 지게차/운반구 DEF' },
            { code: '3', codeName: '4륜 전동 지게차/운반구 GHI' },
          ],
        },
        {
          name: 'col4',
          field: 'col4',
          label: 'LBLREMARK',
          align: 'left',
          style: 'width:200px',
          type: 'text',
          sortable: false,
        },
      ]

        this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col2: '김길동',
          col1: '1',
          col5: '내부',
          insideOutsideTypeCd: 'Y',
        },
        {
          col2: '이민수',
          col1: '1',
          col5: '내부',
          insideOutsideTypeCd: 'Y',
        },
        {
          col2: '고상기',
          col1: '3',
          col5: '내부',
          insideOutsideTypeCd: 'Y',
        },
        {
          col2: '정지영',
          col1: '2',
          col5: '외부',
          insideOutsideTypeCd: 'N',
        },
        {
          col2: '임한별',
          col1: '2',
          col5: '외부',
          insideOutsideTypeCd: 'N',
        }
      ]
    },  
    saveMst() {
    },
    removeRowMst() {
     
    },
    addUser() {
      this.popupOptions.title = 'LBL0000560'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    addRow() {
      this.grid.data.splice(0, 0, {
        insideOutsideTypeCd: 'N',
        col5: '외부',
      })
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            col2: _item.userName,
          });
          if (index === -1) {
            this.grid.data.push({
              userId: _item.userId,
              col2: _item.userName,
              patrolJobName: _item.projectRoleName ? _item.projectRoleName : _item.vendorUserRoleName,
              remark: '',
              col5: '내부',
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
  }
};
</script>
